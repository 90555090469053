import { forwardRef, useEffect, useImperativeHandle } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Course, useCourseSchema } from "@stesvis/metagolf-apis";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";

import {
  CheckboxField,
  Form,
  SelectField,
  TextField,
} from "[root]/src/components/forms";
import { HandleForm } from "[root]/src/lib";
import { useAuthApi } from "../auth";

type GolfCourseFormProps = {
  course?: Course;
  onSubmit: (data: Course) => Promise<boolean>;
};

export const GolfCourseForm = forwardRef<HandleForm, GolfCourseFormProps>(
  ({ course, onSubmit, ...props }, ref) => {
    const { locationsApi } = useAuthApi();

    const schema = useCourseSchema();
    const methods = useForm({
      resolver: yupResolver(schema),
      defaultValues: {
        id: course?.id,
        name: course?.name,
        coordinates: course?.coordinates,
        isDemo: course?.isDemo || false,
        url: course?.url,
        locationId: course?.locationId,
      },
      mode: "all",
    });

    useImperativeHandle(ref, () => ({
      handleSubmit: methods.handleSubmit(async (data) => {
        const success = await onSubmit(data);
        if (success) {
          methods.reset();
        }
      }),
    }));

    useEffect(() => {
      locationsApi.refetch();
    }, [locationsApi]);

    return (
      <Form methods={methods}>
        <Row>
          <Col lg={6}>
            <TextField name="name" label="Name" size="lg" />
          </Col>
          <Col lg={6}>
            <TextField name="coordinates" label="Coordinates" size="lg" />
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <TextField name="url" label="Website" size="lg" />
          </Col>
          <Col lg={6}>
            <SelectField
              name="locationId"
              label="Location"
              items={locationsApi.data?.data?.items || []}
              itemLabelProperty="name"
              itemValueProperty="id"
              size="lg"
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <CheckboxField name="isDemo" description="Is Demo" />
          </Col>
        </Row>
      </Form>
    );
  }
);
