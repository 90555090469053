import Enumerable from "linq";
import React from "react";

export type DataTableCellProps<T> = {
  dataTitle?: string;
  render: (row: T) => React.ReactNode;
  className?: string;
};

export type ConditionalRowStyle<T> = {
  when: (row: T) => boolean;
  classNames: string;
};

type DataTableBodyProps<T> = {
  columns: DataTableCellProps<T>[];
  conditionalRowStyles?: ConditionalRowStyle<T>[];
  EmptyDataComponent: React.ReactNode;
  onRowClick?: (
    row: T,
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>
  ) => void;
  rows: T[];
};

export const DataTableBody = <T,>({
  columns,
  conditionalRowStyles = [],
  EmptyDataComponent,
  onRowClick,
  rows,
  ...props
}: DataTableBodyProps<T>) => {
  return (
    <tbody>
      {(rows?.length > 0 &&
        rows?.map((row) => {
          const additionalClassNames = `${Enumerable.from(conditionalRowStyles)
            .where((x) => x.when(row) === true)
            .select((x) => x.classNames)
            .toJoinedString()} ${onRowClick ? "cur-pointer" : ""}`.trim();

          return (
            <tr
              key={JSON.stringify(row)}
              className={additionalClassNames}
              onClick={(event) => !!onRowClick && onRowClick(row, event)}
            >
              {columns?.map((column, index) => (
                <td
                  key={JSON.stringify(column) + index}
                  className={column.className}
                  data-title={column.dataTitle}
                >
                  {column.render(row)}
                </td>
              ))}
            </tr>
          );
        })) || (
        <tr>
          <td colSpan={columns?.length}>{EmptyDataComponent}</td>
        </tr>
      )}
    </tbody>
  );
};
