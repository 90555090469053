import { useMemo } from "react";

import { UserRole } from "@stesvis/metagolf-apis";

import { useAuthContext } from "[root]/src/contexts";

export const useUserInfo = () => {
  const { currentUser } = useAuthContext();

  const isAdmin = useMemo(() => {
    return (
      currentUser?.roles?.some((role) =>
        [UserRole.admin, UserRole.superAdmin].includes(role as UserRole)
      ) ?? false
    );
  }, [currentUser?.roles]);

  const isSuperAdmin = useMemo(() => {
    return currentUser?.roles?.includes(UserRole.superAdmin) ?? false;
  }, [currentUser?.roles]);

  return { isAdmin, isSuperAdmin };
};
