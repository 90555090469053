import { Card } from "[root]/src/components/molecules";
import {
  AuthenticatedPageTemplate,
  UnauthenticatedPageTemplate,
} from "[root]/src/components/templates";
import { useAuthContext } from "[root]/src/contexts";

type NotFoundPageProps = {};

export const NotFoundPage = (props: NotFoundPageProps) => {
  const { session } = useAuthContext();
  const isAuthenticated = !!session?.token;

  const content = (
    <Card>
      <Card.Body>
        <h1>Not Found</h1>
      </Card.Body>
    </Card>
  );

  return isAuthenticated ? (
    <AuthenticatedPageTemplate>{content}</AuthenticatedPageTemplate>
  ) : (
    <UnauthenticatedPageTemplate>{content}</UnauthenticatedPageTemplate>
  );
};
