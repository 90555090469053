import logo from "[root]/src/assets/images/metagolf.png";
import { HamburgerButton } from "[root]/src/components/layout";
import { Link } from "react-router-dom";
import { UserBox } from "./UserBox";

type HeaderProps = {};

export const Header = (props: HeaderProps) => {
  return (
    <header className="header">
      <div className="logo-container">
        <Link to="#" className="logo">
          <img src={logo} width="150" alt="MetaGolf" />
        </Link>
        <HamburgerButton
          className="d-md-none toggle-sidebar-left"
          dataToggleClassName="sidebar-left-opened"
        />
      </div>
      <div className="header-right">
        <UserBox />
      </div>
    </header>
  );
};
