import { ComponentType } from "react";

import { ComponentBaseProps } from "[root]/src/lib/interfaces";
import { WithErrorProps, withError } from "./withError";
import { WithLabelProps, withLabel } from "./withLabel";
import { WithNotesProps, withNotes } from "./withNotes";

// Define the props the HOC will combine
export function createFormComponent<TProps>(
  Component: ComponentType<TProps>
): ComponentType<
  TProps & WithErrorProps & WithLabelProps & WithNotesProps & ComponentBaseProps
> {
  return withLabel(withNotes(withError(Component)));
}
