import { TableHTMLAttributes } from "react";

import { FilterQueryParams } from "@stesvis/metagolf-apis";
import { useStyleSheet } from "@stesvis/react-core";
import clsx from "clsx";
import Enumerable from "linq";
import { Col, Row, Table } from "react-bootstrap";

import {
  ConditionalRowStyle,
  DataTableBody,
  DataTableCellProps,
} from "./DataTableBody";
import { DataTableHeader, HeaderProps } from "./DataTableHeader";
import {
  DataTablePagination,
  DataTablePaginationProps,
} from "./DataTablePagination";
import { DataTableSearch } from "./DataTableSearch";

type DataTableColumnsProps<T> = {
  header: HeaderProps;
  cell: DataTableCellProps<T>;
};

export type DataTableSortProps = {
  sortColumn?: FilterQueryParams["sortColumn"];
  sortDirection?: FilterQueryParams["sortDir"];
};

type DataTableProps<T> = TableHTMLAttributes<HTMLTableElement> & {
  buttons?: React.ReactNode;
  className?: string;
  columns: DataTableColumnsProps<T>[];
  conditionalRowStyles?: ConditionalRowStyle<T>[];
  data: T[];
  dense?: boolean;
  EmptyDataComponent: React.ReactNode;
  onRowClick?: (row: T) => void;
  onSearch?: (searchValue: string) => void;
  onSort?: (columnId: string, direction: FilterQueryParams["sortDir"]) => void;
  paginate?: boolean;
  search?: boolean;
  searchValue?: string;
  sortColumn?: string;
  sortDirection?: "asc" | "desc";
  paginationProps?: DataTablePaginationProps;
};

export const DataTable = <T,>({
  buttons,
  className,
  columns,
  conditionalRowStyles,
  data,
  dense,
  EmptyDataComponent,
  onRowClick,
  onSearch = () => {},
  onSort = () => {},
  paginate,
  search,
  searchValue,
  sortColumn,
  sortDirection,
  paginationProps,
  ...props
}: DataTableProps<T>) => {
  useStyleSheet(
    "https://cdn.datatables.net/1.12.0/css/dataTables.bootstrap5.min.css"
  );
  useStyleSheet(`${process.env.PUBLIC_URL}/css/data-tables.css`);

  const headers: HeaderProps[] = Enumerable.from(columns)
    .select((x) => x.header)
    .toArray();
  const cells: DataTableCellProps<T>[] = Enumerable.from(columns)
    .select((x) => x.cell)
    .toArray();

  return (
    <>
      <Row className="align-items-center">
        <Col md={6} sm={12}>
          {buttons}
        </Col>
        <Col md={6} sm={12}>
          {!!search && (
            <DataTableSearch onSearch={onSearch} searchValue={searchValue} />
          )}
        </Col>
      </Row>

      {/* **** BOOTSTRAP PAGINATION **** */}
      {!!paginationProps && (
        <DataTablePagination
          className="mb-3"
          from={paginationProps.from}
          lastPage={paginationProps.lastPage}
          page={paginationProps.page}
          pageSize={paginationProps.pageSize}
          total={paginationProps.total}
          to={paginationProps.to}
          onPageChange={paginationProps.onPageChange}
          onPageSizeChange={paginationProps.onPageSizeChange}
          hideRecordsInfo
        />
      )}

      <Table
        className={clsx(dense && "dense", className)}
        {...props}
        striped
        hover
      >
        <DataTableHeader
          headers={headers}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSort={onSort}
        />
        <DataTableBody
          columns={cells}
          conditionalRowStyles={conditionalRowStyles}
          EmptyDataComponent={EmptyDataComponent}
          onRowClick={onRowClick}
          rows={data}
        />
      </Table>

      {/* **** BOOTSTRAP PAGINATION **** */}
      {!!paginationProps && (
        <DataTablePagination
          className="mt-3"
          from={paginationProps.from}
          lastPage={paginationProps.lastPage}
          page={paginationProps.page}
          pageSize={paginationProps.pageSize}
          total={paginationProps.total}
          to={paginationProps.to}
          onPageChange={paginationProps.onPageChange}
          onPageSizeChange={paginationProps.onPageSizeChange}
          hideDropdown
        />
      )}
    </>
  );
};
