import React from "react";

type OverlayProps = React.HTMLAttributes<HTMLDivElement> & {};

export const Overlay = (props: OverlayProps) => {
  return (
    <div className="app-overlay" {...props}>
      <i className="fa fa-spinner fa-spin text-primary" />
    </div>
  );
};
