import clsx from "clsx";
import { Link } from "react-router-dom";

import { ComponentBaseProps } from "[root]/src/lib";
import { CardTitle } from "./CardTitle";

type CardHeaderProps = Pick<ComponentBaseProps, "children" | "className"> & {
  collapsible?: boolean;
  closableProps?: { visible: true; onClose: () => void } | { visible: false };
};

export const CardHeader = ({
  children,
  className,
  collapsible,
  closableProps,
}: CardHeaderProps) => (
  <header className={clsx("card-header", className)}>
    <div className="card-actions">
      {!!collapsible && (
        <Link
          to="#"
          className="card-action card-action-toggle"
          data-card-toggle=""
        />
      )}
      {!!closableProps?.visible && (
        <Link
          to="#"
          className="card-action card-action-dismiss"
          data-card-dismiss=""
          onClick={closableProps.onClose}
        />
      )}
    </div>
    {children}
  </header>
);

CardHeader.Title = CardTitle;
