import { DataResponse, Endpoints, User } from "@stesvis/metagolf-apis";
import { Variant } from "@stesvis/react-core";

import { useApiMutation, useApiQuery } from "[root]/src/hooks";
import { useServices } from "[root]/src/services";

export const useAccountApi = () => {
  const services = useServices();

  // User info API query
  const userInfoApi = useApiQuery({
    queryKey: [Endpoints.Account.userInfo],
    queryFn: async () => await services.api.account.userInfo(),
    enabled: false,
  });

  const accountUpdateApiMutation = useApiMutation<
    DataResponse<User>,
    Error,
    User
  >({
    mutationKey: [Endpoints.Account.edit],
    mutationFn: (data) => services.api.account.update(data),
    onSuccess: (data) => {
      services.utilities.invalidateQuery([Endpoints.Account.userInfo]);
    },
    onError: (error) => {
      services.utilities.toast({
        variant: Variant.error,
        message: error.message,
      });
    },
  });

  return {
    accountUpdateApiMutation,
    userInfoApi,
  };
};
