import React, { useMemo, useState } from "react";

import { FilterQueryParams } from "@stesvis/metagolf-apis";
import clsx from "clsx";
import { ImSortAmountAsc, ImSortAmountDesc } from "react-icons/im";
import { DataTableSortProps } from "./DataTable";

export type HeaderProps = {
  id: string;
  name?: string;
  text?: string;
  sortable: boolean;
  className?: string;
};

type DataTableHeaderProps = DataTableSortProps & {
  headers: HeaderProps[];
  onSort?: (columnId: string, direction: FilterQueryParams["sortDir"]) => void;
};

export const DataTableHeader = ({
  headers,
  onSort = () => {},
  sortColumn,
  sortDirection,
  ...otherProps
}: DataTableHeaderProps) => {
  const [sortData, setSortData] = useState<{
    column: string | null;
    direction: DataTableHeaderProps["sortDirection"] | null;
  }>({ column: null, direction: null });

  const handleHeaderClick = (
    event: React.MouseEvent<HTMLTableCellElement>,
    header: HeaderProps
  ) => {
    event.preventDefault();

    if (header.sortable) {
      const direction =
        header.id === sortData.column
          ? sortData.direction === "asc"
            ? "desc"
            : "asc"
          : "asc";

      setSortData({ column: header.id, direction: direction });
      onSort(header.id, direction);
    }
  };

  const headerColumns = useMemo(
    () =>
      headers?.map((header, index) => {
        const sortIcon =
          sortColumn === header.id && sortData.direction === "desc" ? (
            <ImSortAmountDesc />
          ) : (
            <ImSortAmountAsc />
          );

        return (
          <th
            key={JSON.stringify(header) + index}
            itemID={header.id}
            // name={header.id}
            className={clsx(
              header.className,
              header.sortable && "sortable cur-pointer"
            )}
            onClick={(event) => handleHeaderClick(event, header)}
          >
            <span className="ws-nowrap">
              {header.text} {!!header.sortable && sortIcon}
            </span>
          </th>
        );
      }),
    [headers, sortColumn, sortData] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <thead>
      <tr>{headerColumns}</tr>
    </thead>
  );
};
