import { AuthenticatedPageTemplate } from "../templates";

type DashboardPageProps = {};

export const DashboardPage = (props: DashboardPageProps) => {
  return (
    <AuthenticatedPageTemplate
      titleProps={{
        breadcrumbsProps: [{ title: "Dashboard", active: true }],
        title: "Dashboard",
      }}
    >
      Dashboard
    </AuthenticatedPageTemplate>
  );
};
