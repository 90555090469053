import { useCallback } from "react";

import { ApiSessionProps, useApiService } from "@stesvis/metagolf-apis";
import {
  LocalStorageKeys,
  useServices as useCoreServices,
} from "@stesvis/react-core";

import { useUtilitiesService } from "./useUtilitiesService";

const DEFAULT_BACKEND_URL = "https://backend-staging.metagolf.ca/api/v1";

export const useServices = () => {
  const coreServices = useCoreServices();

  const getSessionCallback = useCallback(async (): Promise<ApiSessionProps> => {
    return Promise.resolve({
      token:
        coreServices.localStorage.getProperty(LocalStorageKeys.accessToken) ||
        "",
      refreshToken:
        coreServices.localStorage.getProperty(LocalStorageKeys.refreshToken) ||
        "",
    });
  }, []);

  return {
    api: useApiService({
      baseURL: process.env.REACT_APP_BACKEND_URL || DEFAULT_BACKEND_URL,
      getSessionCallback,
      platform: "web",
    }),
    core: coreServices,
    utilities: useUtilitiesService(),
  };
};
