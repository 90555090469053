import { useEffect, useState } from "react";

import { Col, Row } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";

import { DropdownButton } from "[root]/src/components/molecules";
import { ComponentBaseProps } from "[root]/src/lib";

export type DataTablePaginationDataProps = {
  from?: number;
  to?: number;
  lastPage?: number;
  total?: number;
};

export type DataTablePaginationProps = Pick<ComponentBaseProps, "className"> &
  DataTablePaginationDataProps & {
    page: number;
    pageSize: number;
    hideRecordsInfo?: boolean;
    hideDropdown?: boolean;
    onPageChange: (page: number) => void;
    onPageSizeChange: (pageSize: number) => void;
  };

export const DataTablePagination = ({
  className,
  from,
  lastPage = 1,
  page = 1,
  pageSize,
  hideRecordsInfo,
  hideDropdown,
  to,
  total,
  onPageChange,
  onPageSizeChange,
  ...otherProps
}: DataTablePaginationProps) => {
  const [currentPage, setCurrentPage] = useState(page);
  const [rowsPerPage, setRowsPerPage] = useState(pageSize);

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    onPageChange(page);
  };

  const handlePageSizeChange = (value: number) => {
    setRowsPerPage(value);
    onPageSizeChange(value);
  };

  const pageSizeItems = [
    { text: "10", value: 10 },
    { text: "25", value: 25 },
    { text: "50", value: 50 },
    { text: "100", value: 100 },
  ];

  return (
    <Row className={className}>
      <Col md={5} sm={12}>
        {!hideDropdown && (
          <div className="dataTables_info dataTables_length mt-1 d-flex align-items-center gap-2">
            <DropdownButton
              // className="d-inline-block"
              items={pageSizeItems}
              itemLabelProperty="text"
              itemValueProperty="value"
              onChange={handlePageSizeChange}
              value={rowsPerPage}
            />
            <span className="ms-2">rows per page</span>
          </div>
        )}
        {!hideRecordsInfo && from != null && to != null && (
          <div className="dataTables_info" role="status" aria-live="polite">
            <span>
              Showing {from}-{to} of {total} rows
            </span>
          </div>
        )}
      </Col>
      <Col md={7} sm={12}>
        {lastPage > 1 && (
          <div
            className="dataTables_paginate paging_simple_numbers"
            {...otherProps}
          >
            <Pagination>
              <Pagination.Prev
                className="paginate_button"
                disabled={page === 1}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <span>Previous</span>
              </Pagination.Prev>

              <Pagination.Item
                active={currentPage === 1}
                className="paginate_button"
                onClick={() => handlePageChange(1)}
              >
                1
              </Pagination.Item>
              <Pagination.Ellipsis className="paginate_button" disabled />
              {currentPage !== 1 && currentPage !== lastPage && (
                <>
                  <Pagination.Item active className="paginate_button">
                    {currentPage}
                  </Pagination.Item>
                  <Pagination.Ellipsis className="paginate_button" disabled />
                </>
              )}
              <Pagination.Item
                active={currentPage === lastPage}
                className="paginate_button"
                onClick={() => handlePageChange(lastPage)}
              >
                {lastPage}
              </Pagination.Item>

              <Pagination.Next
                className="paginate_button"
                disabled={currentPage === lastPage}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </Pagination.Next>
            </Pagination>
          </div>
        )}
      </Col>
    </Row>
  );
};
