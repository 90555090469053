import React, { useState } from "react";
import ReactDOM from "react-dom";

import { Modal, ModalProps } from "[root]/src/components/molecules/modals";

interface ModalContextProps {
  showModal: (props: Omit<ModalProps, "visible">) => void;
  hideModal: () => void;
}

export const ModalContext = React.createContext<ModalContextProps>({
  showModal: () => {},
  hideModal: () => {},
});

export const ModalContextProvider = (props: React.PropsWithChildren) => {
  const [modalProps, setModalProps] = useState<ModalProps>({
    body: undefined,
    buttonsProps: undefined,
    content: undefined,
    size: "lg",
    title: undefined,
    show: false,
  });

  const showModal = (props: Omit<ModalProps, "visible">) => {
    setModalProps({ ...props, show: true });
  };

  const hideModal = () => {
    setModalProps({ ...modalProps, show: false });
  };

  return (
    <ModalContext.Provider value={{ showModal, hideModal }}>
      {props.children}
      {ReactDOM.createPortal(
        <Modal
          body={modalProps.body}
          buttonsProps={modalProps.buttonsProps}
          content={modalProps.content}
          size={modalProps.size}
          title={modalProps.title}
          show={modalProps.show}
        />,
        document.getElementById("overlay-root") as Element
      )}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => React.useContext(ModalContext);
