import { Course } from "@stesvis/metagolf-apis";
import { Variant } from "@stesvis/react-core";
import { LuExternalLink } from "react-icons/lu";
import { Link } from "react-router-dom";

import { DropdownButton } from "[root]/src/components/molecules";
import { useModalContext } from "[root]/src/contexts";
import { HandleForm, IconSize } from "[root]/src/lib";
import { GolfCourseForm } from "./GolfCourseForm";

type CourseDataTableProps = {
  golfCourseFormRef: React.RefObject<HandleForm>;
  handleCreateCourseCallback: (course: Course) => Promise<boolean>;
  submitCreateCourseCallback: () => void;
  handleEditCourseCallback: (course: Course) => Promise<boolean>;
  submitEditCourseCallback: () => void;
  submitDeleteCourseCallback: (id: number) => Promise<boolean>;
};

export const useCourseDataTable = ({
  golfCourseFormRef,
  handleCreateCourseCallback,
  submitCreateCourseCallback,
  handleEditCourseCallback,
  submitEditCourseCallback,
  submitDeleteCourseCallback,
}: CourseDataTableProps) => {
  const { showModal, hideModal } = useModalContext();

  const menuItems = (row: Course) => [
    // Edit
    {
      onClick: () => openEditCourseModal(row),
      title: "Edit",
    },
    { type: "divider" },
    // Delete
    {
      className: "text-danger",
      onClick: () => {
        showModal({
          title: "Delete Golf Course",
          body: "Are you sure you want to delete this golf course?",
          buttonsProps: {
            type: "two-buttons",
            leftButtonProps: {
              title: "Cancel",
              variant: Variant.tertiary,
              onClick: hideModal,
            },
            rightButtonProps: {
              title: "Delete",
              variant: Variant.error,
              onClick: async () => {
                row.id && submitDeleteCourseCallback(row.id);
                hideModal();
              },
            },
          },
        });
      },
      title: "Delete",
    },
  ];

  const columns = [
    {
      header: {
        id: "id",
        text: "",
        sortable: false,
        className: "col-menu",
      },
      cell: {
        dataTitle: "id",
        render: (row: Course) => (
          <DropdownButton
            title={<i className="fa fa-bars" />}
            fixedTitle
            items={menuItems(row)}
            itemLabelProperty="title"
            itemValueProperty="title"
            variant={Variant.link}
            className="w-auto"
          />
        ),
      },
    },
    {
      header: { id: "name", text: "Name", sortable: true },
      cell: {
        dataTitle: "name",
        render: (row: Course) => row.name,
      },
    },
    {
      header: {
        id: "coordinates",
        text: "Coordinates",
        sortable: false,
      },
      cell: {
        dataTitle: "coordinates",
        render: (row: Course) =>
          row.coordinates && (
            <Link
              to={`https://www.google.com/maps?q=${row.coordinates}`}
              target="_blank"
              onClick={(event) => event.stopPropagation()}
            >
              {row.coordinates}
            </Link>
          ),
      },
    },
    {
      header: {
        id: "url",
        text: "Website",
        sortable: false,
      },
      cell: {
        dataTitle: "url",
        render: (row: Course) =>
          row.url && (
            <Link
              className="d-flex flex-row align-items-center"
              to={row.url}
              target="_blank"
              onClick={(event) => event.stopPropagation()}
            >
              Open website&nbsp;&nbsp;
              <LuExternalLink size={IconSize.small} />
            </Link>
          ),
      },
    },
    {
      header: {
        id: "location",
        text: "Location",
        sortable: true,
      },
      cell: {
        dataTitle: "location",
        render: (row: Course) => row.location?.name,
      },
    },
    {
      header: {
        id: "isDemo",
        text: "Demo",
        sortable: false,
      },
      cell: {
        dataTitle: "demo",
        render: (row: Course) =>
          row.isDemo ? (
            <label className="badge badge-secondary">Demo</label>
          ) : undefined,
      },
    },
  ];

  const openCreateCourseModal = () =>
    showModal({
      title: "Add Golf Course",
      size: "lg",
      body: (
        <GolfCourseForm
          ref={golfCourseFormRef}
          onSubmit={async (data) => {
            const success = await handleCreateCourseCallback(data);
            if (success) hideModal();

            return success;
          }}
        />
      ),
      buttonsProps: {
        type: "two-buttons",
        leftButtonProps: {
          title: "Cancel",
          variant: Variant.tertiary,
          onClick: hideModal,
        },
        rightButtonProps: {
          title: "Save",
          variant: Variant.primary,
          onClick: submitCreateCourseCallback,
        },
      },
    });

  const openEditCourseModal = (course: Course) =>
    showModal({
      title: "Edit Golf Course",
      size: "lg",
      body: (
        <GolfCourseForm
          ref={golfCourseFormRef}
          course={course}
          onSubmit={async (data) => {
            const success = await handleEditCourseCallback(data);
            if (success) hideModal();

            return success;
          }}
        />
      ),
      buttonsProps: {
        type: "two-buttons",
        leftButtonProps: {
          title: "Cancel",
          variant: Variant.tertiary,
          onClick: hideModal,
        },
        rightButtonProps: {
          title: "Save",
          variant: Variant.primary,
          onClick: submitEditCourseCallback,
        },
      },
    });

  return { columns, menuItems, openCreateCourseModal, openEditCourseModal };
};
