import { useScript } from "@stesvis/react-core";

import { Overlay } from "[root]/src/components/atoms";
import {
  Header,
  SideBar,
  TitleBar,
  TitleBarProps,
} from "[root]/src/components/layout";
import { ComponentBaseProps } from "[root]/src/lib";

type AuthenticatedPageTemplateProps = Pick<ComponentBaseProps, "children"> & {
  titleProps?: Pick<TitleBarProps, "title" | "breadcrumbsProps">;
  loading?: boolean;
};

export const AuthenticatedPageTemplate = ({
  children,
  titleProps,
  loading,
  ...props
}: AuthenticatedPageTemplateProps) => {
  useScript(`${process.env.PUBLIC_URL}/theme/js/theme.js`);
  useScript(`${process.env.PUBLIC_URL}/js/sidebar-menu.js`);
  useScript(`${process.env.PUBLIC_URL}/js/header-nav.js`);
  useScript(`${process.env.PUBLIC_URL}/theme/js/theme.init.js`);

  return (
    <>
      {loading && <Overlay />}

      <section className="body">
        <Header />
        <div className="inner-wrapper">
          <SideBar />
          <section
            role="main"
            className="content-body content-body-modern d-flex flex-column"
            style={{ minHeight: "calc(100vh - 110px)" }}
          >
            <TitleBar {...titleProps} />
            {children}
          </section>
        </div>
      </section>
    </>
  );
};
