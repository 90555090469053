import {
  DataResponse,
  Endpoints,
  FilterResponse,
  Location,
} from "@stesvis/metagolf-apis";
import { Variant } from "@stesvis/react-core";
import { useApiMutation, useApiQuery } from "[root]/src/hooks";

import { useServices } from "[root]/src/services";

export const useLocationsApi = () => {
  const services = useServices();

  // Locations API query (enabled if on the "register" segment)
  const locationsApi = useApiQuery({
    queryKey: [Endpoints.Locations.all],
    queryFn: async (): Promise<FilterResponse<Location>> =>
      await services.api.locations.all(),
    // enabled: segments.includes("register"),
    enabled: false,
  });

  const locationsCreateApiMutation = useApiMutation<
    DataResponse<Location>,
    Error,
    Location
  >({
    mutationKey: [Endpoints.Locations.create],
    mutationFn: (data) => services.api.locations.create(data),
    onSuccess: (data) => {
      services.utilities.invalidateQuery([Endpoints.Locations.all]);
    },
    onError: (error) => {
      services.utilities.toast({
        variant: Variant.error,
        message: error.message,
      });
    },
  });

  const locationsDeleteApiMutation = useApiMutation<
    DataResponse<string>,
    Error,
    { id: number }
  >({
    mutationKey: [Endpoints.Locations.delete],
    mutationFn: ({ id }) => services.api.locations.delete(id),
    onSuccess: (data) => {
      services.utilities.invalidateQuery([Endpoints.Locations.all]);
    },
    onError: (error) => {
      services.utilities.toast({
        variant: Variant.error,
        message: error.message,
      });
    },
  });

  const locationsUpdateApiMutation = useApiMutation<
    DataResponse<Location>,
    Error,
    Location
  >({
    mutationKey: [Endpoints.Locations.edit],
    mutationFn: (data) => services.api.locations.update(Number(data.id), data),
    onSuccess: (data) => {
      services.utilities.invalidateQuery([Endpoints.Locations.all]);
    },
    onError: (error) => {
      services.utilities.toast({
        variant: Variant.error,
        message: error.message,
      });
    },
  });

  return {
    locationsApi,
    locationsCreateApiMutation,
    locationsDeleteApiMutation,
    locationsUpdateApiMutation,
  };
};
