import { ComponentBaseProps } from "[root]/src/lib";
import clsx from "clsx";

type FlexRowProps = Pick<ComponentBaseProps, "children" | "className"> & {
  style?: React.CSSProperties;
};

export const FlexRow = ({
  children,
  className,
  style,
  ...props
}: FlexRowProps) => {
  return (
    <div className={clsx("d-flex flex-row", className)} style={style}>
      {children}
    </div>
  );
};
