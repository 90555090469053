import { useEffect, useState } from "react";

import {
  Course,
  DataResponse,
  Endpoints,
  HoleGroup,
  Tee,
} from "@stesvis/metagolf-apis";
import { Variant } from "@stesvis/react-core";
import Enumerable from "linq";
import { Col, Row } from "react-bootstrap";
import { GiGolfTee } from "react-icons/gi";
import { IoGolf } from "react-icons/io5";
import { LuExternalLink } from "react-icons/lu";
import { Link, useParams } from "react-router-dom";
import { v4 } from "uuid";

import { Button, FlexRow } from "[root]/src/components/atoms";
import { withLabel } from "[root]/src/components/hocs";
import { Card, Tabs } from "[root]/src/components/molecules";
import { AuthenticatedPageTemplate } from "[root]/src/components/templates";
import { useApiQuery } from "[root]/src/hooks";
import { IconSize, MGRoutes } from "[root]/src/lib";
import { useServices } from "[root]/src/services";
import { HolesGroupForm } from "./holes";
import { TeeForm } from "./tees";

type GolfCourseDetailsPageProps = {};

const DivWithLabel = withLabel((props) => <div {...props} />);

export const GolfCourseDetailsPage = (props: GolfCourseDetailsPageProps) => {
  const { id } = useParams();
  const services = useServices();
  const [holesGroups, setHolesGroups] = useState<HoleGroup[]>([]);
  const [tees, setTees] = useState<Tee[]>([]);
  const [isSavingHolesGroup, setIsSavingHolesGroup] = useState(false);

  const courseApiQuery = useApiQuery({
    queryKey: [Endpoints.Courses.get, Number(id)],
    queryFn: async (): Promise<DataResponse<Course>> =>
      await services.api.courses.get(
        Number(id),
        "Location, Holes, Tees" // child entities
      ),
    enabled: !!id,
  });
  const course = courseApiQuery.data?.data;

  useEffect(() => {
    setHolesGroups(
      course?.holesGroups && course.holesGroups.length > 0
        ? course.holesGroups
        : [{ groupName: "" }]
    );
    setTees(
      course?.tees && course.tees.length > 0
        ? course.tees
        : [{ courseId: Number(id), name: "", holesDetails: [] }]
    );
  }, [course, id]);

  const handleAddHoles = () => {
    setHolesGroups([
      ...holesGroups,
      {
        groupName: "",
      },
    ]);
  };

  const handleAddTee = () => {
    setTees([
      ...tees,
      {
        courseId: Number(id),
        name: "",
        holesDetails: [],
      },
    ]);
  };

  return (
    <AuthenticatedPageTemplate
      titleProps={{
        breadcrumbsProps: [
          { title: "Dashboard", to: MGRoutes.dashboard },
          { title: "Golf Courses", to: MGRoutes.golfCourses },
          { title: "Golf Course Details", active: true },
        ],
        title: "Golf Course Details",
      }}
      loading={courseApiQuery.isLoading || isSavingHolesGroup}
    >
      <Card>
        <Card.Header>
          <h2 className="card-title">{course?.name}</h2>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <DivWithLabel label="Name">{course?.name}</DivWithLabel>
            </Col>
            <Col>
              <DivWithLabel label="Coordinates">
                {course?.coordinates ? (
                  <Link
                    to={`https://www.google.com/maps?q=${course.coordinates}`}
                    target="_blank"
                    onClick={(event) => event.stopPropagation()}
                  >
                    {course.coordinates}
                  </Link>
                ) : (
                  "-"
                )}
              </DivWithLabel>
            </Col>
            <Col>
              <DivWithLabel label="Area">
                {course?.location?.name || "-"}
              </DivWithLabel>
            </Col>
            <Col>
              <DivWithLabel label="Website">
                {course?.url ? (
                  <Link
                    className="d-flex flex-row align-items-center gap-2"
                    to={course.url}
                    target="_blank"
                    onClick={(event) => event.stopPropagation()}
                  >
                    Open website <LuExternalLink size={IconSize.small} />
                  </Link>
                ) : (
                  "-"
                )}
              </DivWithLabel>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="card-horizontal">
        <Card.Header className="w-25">
          <div className="card-header-icon bg-quaternary d-flex align-items-center justify-content-center">
            <IoGolf />
          </div>
          {/* <h2 className="card-title d-flex flex-row align-items-center gap-3">
              <IoGolf />
              Holes
            </h2> */}
        </Card.Header>
        <Card.Body>
          <FlexRow className="justify-content-between">
            <h2 className="card-title">Holes</h2>
            <Button
              className="fw-semibold fs-6"
              variant={Variant.secondary}
              onClick={handleAddHoles}
              size="sm"
            >
              + 9
            </Button>
          </FlexRow>

          {/* {holesGroups?.map((group, index) => (
            <Toggle
              key={`holesGroup-${index}`}
              label={group.groupName}
              labelClassName="text-white"
            >
              <HolesGroupForm
                courseId={Number(id)}
                holeGroup={group}
                firstHoleNumber={index * 9 + 1}
                onLoading={setIsSavingHolesGroup}
              />
            </Toggle>
          ))} */}
          <Tabs
            className="tabs-secondary"
            tabs={holesGroups?.map((group, index) => ({
              id: v4(),
              title: group.groupName,
              content: (
                <HolesGroupForm
                  courseId={Number(id)}
                  holeGroup={group}
                  firstHoleNumber={index * 9 + 1}
                  onLoading={setIsSavingHolesGroup}
                />
              ),
            }))}
          />
        </Card.Body>
      </Card>

      {/* <Card className="card-horizontal">
        <Card.Header className="w-25">
          <div className="card-header-icon bg-quaternary d-flex align-items-center justify-content-center">
            <GiGolfTee />
          </div>
        </Card.Header>
        <Card.Body>
          <h2 className="card-title d-flex flex-row align-items-center justify-content-between mb-3">
            Tees
          </h2>
          <TeesForm courseId={Number(id)} tees={tees}></TeesForm>
        </Card.Body>
      </Card> */}

      <Card className="card-horizontal">
        <Card.Header className="w-25">
          <div className="card-header-icon bg-quaternary d-flex align-items-center justify-content-center">
            <GiGolfTee />
          </div>
          {/* <h2 className="card-title d-flex flex-row align-items-center gap-3">
              <GiGolfTee />
              Tees
            </h2> */}
        </Card.Header>
        <Card.Body>
          <FlexRow className="justify-content-between">
            <h2 className="card-title">Tees</h2>
            <Button
              className="fw-semibold fs-6"
              variant={Variant.secondary}
              onClick={handleAddTee}
              size="sm"
              type="button"
            >
              + 1
            </Button>
          </FlexRow>
          <Tabs
            className="tabs-secondary"
            tabs={tees?.map((tee) => ({
              id: v4(),
              title: tee.name,
              content: (
                <TeeForm
                  courseId={Number(id)}
                  tee={tee}
                  holes={Enumerable.from(holesGroups)
                    .selectMany((x) => x.holes ?? [])
                    .toArray()}
                />
              ),
            }))}
          />
        </Card.Body>
      </Card>
    </AuthenticatedPageTemplate>
  );
};
