import { ErrorResponse, isErrorResponse } from "@stesvis/metagolf-apis";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { AxiosError, HttpStatusCode } from "axios";

import { useAuthContext } from "[root]/src/contexts";
import { useServices } from "[root]/src/services";

export const useApiError = (isError?: boolean, error?: Error) => {};

// make a hook called useApiQuery that extends the react-query useQuery hook

export const useApiQuery = <TData, TError = AxiosError>(
  options: UseQueryOptions<TData, TError>
) => {
  const { session, saveSession, signOut } = useAuthContext();
  const services = useServices();

  // Modify retry logic for the query, only retry once
  const modifiedOptions = {
    ...options,
    retry: (failureCount: number, error: TError) => {
      // Retry once only if the error is Unauthorized (401) or Token Expired (419)
      if (error instanceof AxiosError || isErrorResponse(error)) {
        if (failureCount >= 1) return exit();

        if (shouldRetry(error)) {
          if (!session?.token || !session?.refreshToken) return exit();

          // attempt to get new session
          services.api.auth
            .refreshToken({
              access_token: session?.token,
              refresh_token: session?.refreshToken,
            })
            .then((response) => {
              if (response.access_token && response.refresh_token) {
                saveSession({
                  token: response.access_token,
                  refreshToken: response.refresh_token,
                });
              }
            })
            .catch((error) => {
              return exit();
            });

          return failureCount < 1; // Retry once
        }
      }

      return false; // No retry for other errors
    },
    onError: (error: TError) => {
      // Handle additional error logic here if needed
      console.error(error);
    },
    onSuccess: (data: TData) => {
      // Handle success logic here if needed
    },
  };

  function exit() {
    signOut();
    return false;
  }

  function shouldRetry(error: AxiosError | ErrorResponse) {
    const axiosError = error as AxiosError;
    const responseError = error as ErrorResponse;

    return (
      axiosError.response?.status === HttpStatusCode.Unauthorized ||
      axiosError.response?.status === 419 ||
      responseError?.statusCode === HttpStatusCode.Unauthorized ||
      responseError?.statusCode === 419
    );
  }

  // Return the query with the modified options
  return useQuery<TData, TError>(modifiedOptions);
};
