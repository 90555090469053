import clsx from "clsx";

import { Overlay } from "[root]/src/components/atoms";
import { ComponentBaseProps } from "[root]/src/lib";

type UnauthenticatedPageTemplateProps = Pick<
  ComponentBaseProps,
  "children" | "className"
> & {
  loading?: boolean;
};

export const UnauthenticatedPageTemplate = ({
  children,
  className,
  loading,
  ...props
}: UnauthenticatedPageTemplateProps) => {
  return (
    <>
      {loading && <Overlay />}

      <section className={clsx(className)}>{children}</section>
    </>
  );
};
