import { useEffect, useMemo, useState } from "react";
import ReatcSelect, {
  ActionMeta,
  components,
  InputActionMeta,
  OptionProps,
  Props as ReactSelectProps,
  StylesConfig,
} from "react-select";
import { DropdownButtonProps } from "./DropdownButton";

export type SelectProps = Pick<
  ReactSelectProps,
  | "isSearchable"
  | "isDisabled"
  | "className"
  | "isClearable"
  | "isMulti"
  | "onBlur"
  | "placeholder"
  | "value"
  | "name"
> &
  Pick<DropdownButtonProps, "size"> & {
    allItems?: any[];
    autocomplete?: boolean;
    initialText?: string;
    itemLabelProperty: string;
    items: any[];
    itemValueProperty: string;
    onChange?: (item: any) => void;
    onInputChange?: (text: string) => void;
  };

export const Select = ({
  allItems,
  autocomplete,
  className,
  isClearable,
  isDisabled,
  isMulti,
  initialText,
  itemLabelProperty,
  items,
  itemValueProperty,
  name,
  onBlur,
  onChange,
  onInputChange,
  placeholder,
  isSearchable,
  value,
  size,
  ...otherProps
}: SelectProps) => {
  const [selectedItem, setSelectedItem] = useState<any>(
    findSelectedItem(value)
  );

  useEffect(() => {
    setSelectedItem(findSelectedItem(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allItems, items, value]);

  function findSelectedItem(selectedValue: any) {
    const allOptions = allItems ?? items;

    if (!selectedValue) return null;

    let match = null;
    if (isMulti)
      match = allOptions?.filter((x) =>
        selectedValue?.includes(x?.[itemValueProperty])
      );
    else
      match = allOptions?.find((x) => x?.[itemValueProperty] === selectedValue);

    if (!match || match?.length === 0) {
      const options = allOptions?.flatMap((x) => x.options);

      if (isMulti)
        match = options?.filter((x) =>
          selectedValue?.includes(x?.[itemValueProperty])
        );
      else
        match = options?.find((x) => x?.[itemValueProperty] === selectedValue);
    }

    return match;
  }

  const handleChange = (item: any, actionMeta: ActionMeta<any>) => {
    setSelectedItem(item);
    onChange?.(item);
  };

  const handleInputChange = (text: string, event: InputActionMeta) => {
    if (event.action === "input-change") {
      onInputChange?.(text);
    }
  };

  const Input = useMemo(
    () => (props: any) => {
      return <components.Input name={name} {...props} />;
    },
    [name]
  );

  return (
    <ReatcSelect
      className={className}
      classNamePrefix="react-select"
      classNames={{
        input: () => "",
      }}
      components={{ Input }}
      filterOption={(option, search) => {
        if (!search) return true;

        return autocomplete
          ? true
          : option?.label?.toLowerCase()?.includes(search?.toLowerCase());
      }}
      getOptionLabel={(option) => option[itemLabelProperty]}
      getOptionValue={(option) => option[itemValueProperty]}
      isClearable={isClearable ?? isSearchable}
      isDisabled={isDisabled}
      isMulti={isMulti}
      isSearchable={isSearchable}
      name={name}
      onBlur={onBlur}
      onChange={handleChange}
      onInputChange={handleInputChange}
      options={items}
      placeholder={placeholder}
      styles={getSelectStyles()}
      value={selectedItem}
      {...otherProps}
    />
  );

  function getSelectStyles(): StylesConfig<OptionProps, boolean> {
    return {
      control: (provided, state) => ({
        ...provided,
        backgroundColor: "#000000",
        borderColor: "#282d36 !important",
        borderRadius: size === "lg" ? "4px" : "0.25rem",
        borderWidth: 0,
        boxShadow: state.isFocused
          ? "0 1px 1px rgb(0 0 0 / 8%) inset, 0 0 8px rgb(223 105 26 / 30%) !important;"
          : undefined,
        minHeight: 31,
        height: size === "lg" ? 48 : size === "sm" ? 31 : 39,
        paddingLeft: 16,
        // padding:
        //   size === "lg"
        //     ? ".5rem 1rem"
        //     : size === "sm"
        //     ? ".25rem .5rem"
        //     : ".375rem .75rem",
      }),
      container: (provided) => ({
        ...provided,
        width: "100%",
        padding: 0,
      }),
      groupHeading: (provided) => ({
        ...provided,
        color: "#f6a821",
      }),
      input: (provided) => ({
        ...provided,
        color: "#eeeeee",
        fontSize:
          size === "lg" ? "1.25rem" : size === "sm" ? "0.8rem" : "0.85rem",
      }),
      menuList: (provided) => ({
        ...provided,
        backgroundColor: "#000000",
      }),
      option: (provided, state) => {
        state.innerProps.onMouseDown = (event) =>
          ((event.target as HTMLElement).style.backgroundColor = "#d3121a");

        return {
          ...provided,
          backgroundColor:
            state.isSelected || state.isFocused ? "#85292c" : "revert",
          color: state.isSelected ? "#ffffff" : "#eeeeee",
          fontFamily:
            "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
        };
      },
      placeholder: (provided) => ({
        ...provided,
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: "#eeeeee",
        fontFamily:
          "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
        fontSize:
          size === "lg" ? "1.25rem" : size === "sm" ? "0.8rem" : "0.85rem",
        opacity: state.isDisabled ? 0.75 : 1,
        display: "flex",
        alignItems: "center",
        height: "100%",
      }),
      valueContainer: (provided) => ({
        ...provided,
        margin: 0,
        padding: 0,
        // padding: ".375rem .75rem",
        "& .react-select__input-container": {},
      }),
    };
  }
};
