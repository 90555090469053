import clsx from "clsx";

import { ComponentBaseProps } from "[root]/src/lib";

type HamburgerButtonProps = Pick<ComponentBaseProps, "className"> & {
  dataToggleClassName?: string;
};

export const HamburgerButton = ({
  className,
  dataToggleClassName,
  ...props
}: HamburgerButtonProps) => {
  return (
    <div
      className={clsx("sidebar-toggle hidden-xs", className)}
      data-toggle-class={dataToggleClassName}
      data-target="html"
      data-fire-event="sidebar-left-toggle"
    >
      <i className="fa fa-bars" aria-label="Toggle sidebar"></i>
    </div>
  );
};
