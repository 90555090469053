import React from "react";

import clsx from "clsx";
import { Link, LinkProps } from "react-router-dom";

export type BreadcrumbItemProps = Pick<LinkProps, "title"> & {
  to?: LinkProps["to"];
  icon?: string;
  IconComponent?: React.FC;
  active?: boolean;
};

export const BreadcrumbItem = ({
  to,
  title,
  active,
  icon,
  IconComponent,
  ...props
}: BreadcrumbItemProps) => {
  const clickableItem = to && (
    <Link to={to} className={clsx(active && "active")}>
      <span>
        {(icon && <i className={icon} />) ||
          (IconComponent ? <IconComponent /> : null)}
        <span>{title}</span>
      </span>
    </Link>
  );

  const textItem = (
    <span className={clsx(active ? "active" : "")}>
      {(icon && <i className={icon} />) ||
        (IconComponent ? <IconComponent /> : null)}
      <span>{title}</span>
    </span>
  );

  // either return the link or just the text
  return <li>{clickableItem ?? textItem}</li>;
};
