import clsx from "clsx";
import React from "react";

// Types for the props
export interface WithLabelProps {
  label?: string;
  labelClassName?: string; // Changed to string since it's just a className in React.js
  containerClassName?: string;
  required?: boolean;
}

export function withLabel<TProps extends React.ComponentPropsWithoutRef<any>>(
  Component: React.ComponentType<TProps>
): React.ComponentType<TProps & WithLabelProps> {
  return function WithLabel({
    label,
    labelClassName,
    containerClassName,
    required = false,
    ...props
  }: TProps & WithLabelProps) {
    return (
      <div className={containerClassName}>
        {!!label ? (
          <>
            <label className={clsx("form-label", labelClassName)}>
              {label}
              {required && <span className="text-red-600"> *</span>}
              {/* Required indicator */}
            </label>
            <Component {...(props as TProps)} />
          </>
        ) : (
          //  Pass remaining props to the wrapped component
          <Component {...(props as TProps)} />
        )}
      </div>
    );
  };
}
