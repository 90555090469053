import { Variant } from "@stesvis/react-core";
import { QueryKey, useQueryClient } from "@tanstack/react-query";
import { toast as toastify, ToastOptions, ToastPosition } from "react-toastify";

interface Response {
  message?: string;
  details?: string;
  statusCode?: number;
}

export interface ToastProps {
  message: string | Response;
  variant?: Variant;
  fillColor?: boolean;
  duration?: ToastOptions["autoClose"];
  position?: ToastPosition;
  config?: ToastOptions;
  onOpen?: ToastOptions["onOpen"];
  onClose?: ToastOptions["onClose"];
}

export const useUtilitiesService = () => {
  const queryClient = useQueryClient();

  const invalidateQuery = (queryKey: QueryKey) => {
    queryClient.invalidateQueries({
      queryKey: queryKey,
    });
  };

  /**
   *
   * @param queryKeys If undefined, invalidates all queries
   * @returns
   */
  const invalidateQueries = (queryKeys?: string[]) => {
    if (!queryKeys) {
      // invalidate all queries
      queryClient.invalidateQueries();
      return;
    }

    queryClient.invalidateQueries({
      exact: false,
      predicate: (query) =>
        query.queryKey.every((key) =>
          queryKeys.includes(query.queryKey[0] as string)
        ),
    });
  };

  const toast = ({
    message,
    variant = Variant.info,
    fillColor = false,
    duration = 5000,
    config = {},
    onOpen,
    onClose,
  }: ToastProps): void => {
    // basic configuration
    const toastConfig: ToastOptions = {
      ...config,
      ...(fillColor ? { theme: "colored" } : {}),
      autoClose: duration,
      onOpen: onOpen,
      onClose: onClose,
    };

    let messageText = typeof message === "string" ? message : "";
    if (!message && variant === Variant.error)
      messageText = "The request could not be completed";

    if (typeof message === "object" && message?.message)
      messageText = message.message;
    if (typeof message === "object" && message?.details)
      messageText = `${messageText}\n\n${message.details}`;

    switch (variant) {
      case Variant.error:
        toastify.error(messageText, toastConfig);
        break;

      case Variant.info:
        toastify.info(messageText, toastConfig);
        break;

      case Variant.success:
        toastify.success(messageText, toastConfig);
        break;

      case Variant.warning:
        toastify.warn(messageText, toastConfig);
        break;

      default:
        toastify(messageText, { ...toastConfig, theme: "dark" });
        break;
    }
  };

  return { invalidateQuery, invalidateQueries, toast };
};
