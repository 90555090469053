import clsx from "clsx";
import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";

import { NavLinkProps } from "react-router-dom";

// Define the props as a type
export type SideBarMenuItemProps = NavLinkProps & {
  active?: boolean;
  badgeTitle?: string;
  children?: ReactNode;
  color?: string;
  icon?: string;
  IconComponent?: React.ComponentType;
  parent?: boolean;
};

export const SideBarMenuItem: React.FC<SideBarMenuItemProps> = ({
  children,
  className,
  active,
  badgeTitle,
  color,
  to,
  icon,
  IconComponent,
  parent,
  title,
  ...props
}) => {
  const liClassName = clsx(
    active && "nav-active nav-expanded",
    parent && "nav-parent",
    className
  );
  const linkClass = clsx("nav-link", color && `text-${color}`);

  return (
    <li className={liClassName}>
      <NavLink className={linkClass} to={to}>
        {badgeTitle && <span className={`float-end badge`}>{badgeTitle}</span>}
        {icon ? <i className={icon}></i> : IconComponent && <IconComponent />}
        <span>{title}</span>
      </NavLink>
      {children}
    </li>
  );
};
