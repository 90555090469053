import { Variant } from "@stesvis/react-core";
import clsx from "clsx";
import { Col, Row } from "react-bootstrap";

import { Button, type ButtonProps } from "[root]/src/components/atoms";
import { ComponentBaseProps } from "[root]/src/lib";

type FooterButton = Pick<
  ButtonProps,
  "disabled" | "icon" | "variant" | "onClick" | "className"
> & {
  title: string;
};

type OneButtonFooterProps = {
  type: "one-button";
  centerButtonProps: FooterButton;
};

type TwoButtonsFooterProps = {
  type: "two-buttons";
  leftButtonProps: FooterButton;
  rightButtonProps: FooterButton;
};

export type FooterButtonsProps = (
  | OneButtonFooterProps
  | TwoButtonsFooterProps
) &
  Pick<ComponentBaseProps, "className"> & {};

export const FooterButtons = ({ className, ...props }: FooterButtonsProps) => {
  return (
    <Row>
      <Col className={clsx("d-flex flex-row gap-3", className)}>
        {props.type === "two-buttons" && (
          <>
            <Button
              variant={props.leftButtonProps.variant ?? Variant.secondary}
              icon={props.leftButtonProps.icon}
              disabled={props.leftButtonProps.disabled}
              onClick={props.leftButtonProps.onClick}
              //   className="w-50"
            >
              {props.leftButtonProps.title}
            </Button>
            <Button
              variant={props.rightButtonProps.variant ?? Variant.secondary}
              icon={props.rightButtonProps.icon}
              disabled={props.rightButtonProps.disabled}
              onClick={props.rightButtonProps.onClick}
              //   className="w-50"
            >
              {props.rightButtonProps.title}
            </Button>
          </>
        )}
        {props.type === "one-button" && (
          <Button
            variant={props.centerButtonProps.variant ?? Variant.secondary}
            icon={props.centerButtonProps.icon}
            disabled={props.centerButtonProps.disabled}
            onClick={props.centerButtonProps.onClick}
            // className="w-75"
          >
            {props.centerButtonProps.title}
          </Button>
        )}
      </Col>
    </Row>
  );
};
