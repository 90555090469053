import { useController, useFormContext } from "react-hook-form";

import { createFormComponent } from "[root]/src/components/hocs";
import { Select, SelectProps } from "[root]/src/components/molecules";
import { FormComponentBaseProps } from "[root]/src/lib";

type SelectFieldProps = FormComponentBaseProps & SelectProps;

const SelectFormComponent = createFormComponent(Select);

export const SelectField = ({ name, ...props }: SelectFieldProps) => {
  const { control } = useFormContext();

  const { field, fieldState } = useController({
    name,
    control,
    defaultValue: "",
  });

  return (
    <SelectFormComponent
      error={fieldState.error}
      onChange={(selectedItem: any) =>
        field.onChange(
          selectedItem ? selectedItem[props.itemValueProperty] : ""
        )
      }
      onBlur={props.onBlur || field.onBlur}
      value={field.value}
      {...props}
    />
  );
};
