import { debounce, throttle } from "lodash";
import { useState } from "react";

import { useMemo } from "react";

type DataTableSearchProps = {
  onSearch?: (searchValue: string) => void;
  searchValue?: string;
};

export const DataTableSearch = ({
  onSearch = () => {},
  searchValue,
  ...props
}: DataTableSearchProps) => {
  const [searchText, setSearchText] = useState(searchValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    // handleChangeWithThrottle(event);
    handleChangeWithDebounce(event);
  };

  const handleChangeWithDebounce = useMemo(
    () =>
      debounce((event) => {
        onSearch(event.target.value?.trim());
      }, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleChangeWithThrottle = useMemo(
    () =>
      throttle((event) => {
        onSearch(event.target.value?.trim());
      }, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className="dataTables_filter">
      <label>
        <input
          type="search"
          className="form-control pull-right"
          placeholder="Search..."
          onChange={handleChange}
          style={{
            borderColor: !!searchText ? "#95c93f" : "#242830",
            color: "#95c93f",
          }}
          value={searchText}
        />
      </label>
    </div>
  );
};
