import { ComponentBaseProps } from "[root]/src/lib";
import clsx from "clsx";
import { useState } from "react";

import {
  ButtonGroup,
  Dropdown,
  DropdownButton as RBDropdownButton,
  DropdownButtonProps as RBDropdownButtonProps,
} from "react-bootstrap";

export type DropdownButtonProps = Pick<
  ComponentBaseProps,
  "className" | "variant"
> &
  Omit<RBDropdownButtonProps, "onChange" | "title" | "children"> & {
    title?: React.ReactNode | string;
    fixedTitle?: boolean;
    items: Array<any>;
    itemLabelProperty: string;
    itemValueProperty: string;
    onChange?: (selectedValue: any, selectedText: string) => void;
    placeholder?: string;
    value?: any;
  };

export const DropdownButton = ({
  className,
  fixedTitle = false,
  items = [],
  itemLabelProperty,
  itemValueProperty,
  onChange = () => {},
  placeholder = "",
  title,
  value,
  variant,
  ...props
}: DropdownButtonProps) => {
  const [buttonTitle, setButtonTitle] = useState<string>(
    title
      ? title
      : value
      ? items?.find((x) => x[itemValueProperty] === value)?.[
          itemLabelProperty
        ] ?? items[0]?.[itemLabelProperty]
      : placeholder
  );

  const handleChange = (selectedValue: any, event: any) => {
    const selectedText = event?.target?.innerText;
    if (!fixedTitle) setButtonTitle(selectedText);
    onChange(selectedValue, selectedText);
  };

  return (
    <RBDropdownButton
      as={ButtonGroup}
      className={clsx("btn-block", className)}
      onClick={(event) => event.stopPropagation()}
      onSelect={handleChange}
      title={buttonTitle}
      variant={variant || "default"}
      size="sm"
      {...props}
    >
      {items?.map((item, index) =>
        item.type === "divider" ? (
          <Dropdown.Divider key={`divider-${index}`} />
        ) : (
          <Dropdown.Item
            className={item.className}
            eventKey={item[itemValueProperty]}
            href={item.href}
            onClick={item.onClick}
            key={`${item[itemValueProperty]}-${index}`}
          >
            {item[itemLabelProperty]}
          </Dropdown.Item>
        )
      )}
    </RBDropdownButton>
  );
};
