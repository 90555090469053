import React from "react";

import { Modal as RBModal, ModalProps as RBModalProps } from "react-bootstrap";

import { Card } from "[root]/src/components/molecules/card";
import {
  FooterButtons,
  FooterButtonsProps,
} from "[root]/src/components/molecules/FooterButtons";
import { useModalContext } from "[root]/src/contexts";

export type ModalProps = Pick<RBModalProps, "size"> &
  Pick<RBModalProps, "show"> & {
    title?: string;
    content?: React.ReactNode;
    body?: React.ReactNode;
    buttonsProps?: FooterButtonsProps;
  };

export const Modal = ({
  title,
  size,
  content,
  body,
  buttonsProps,
  ...props
}: ModalProps) => {
  const { hideModal } = useModalContext();

  return (
    <RBModal
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      {...props}
    >
      <Card>
        {!!title && (
          <Card.Header closableProps={{ visible: true, onClose: hideModal }}>
            <h2 className="card-title">{title}</h2>
          </Card.Header>
        )}
        {/* if you pass content it will be used instead of body and buttons */}
        {!!content ? (
          content
        ) : (
          <>
            {body && <Card.Body>{body}</Card.Body>}

            {!!buttonsProps && (
              <Card.Footer>
                <FooterButtons
                  className="justify-content-end"
                  {...buttonsProps}
                />
              </Card.Footer>
            )}
          </>
        )}
      </Card>
    </RBModal>
  );
};
