import React, { useEffect, useState } from "react";

import clsx from "clsx";
import { Link } from "react-router-dom";

import { ComponentBaseProps } from "[root]/src/lib";

type Tab = {
  id: string;
  title: string;
  content: React.ReactNode;
  icon?: string;
};

type TabsProps = Pick<ComponentBaseProps, "className"> & {
  tabs: Tab[];
};

export const Tabs = ({ tabs, className, ...props }: TabsProps) => {
  const [activeTab, setActiveTab] = useState(tabs?.[0]?.id);
  const [myTabs, setMyTabs] = useState(tabs);

  useEffect(() => {
    setMyTabs(tabs);
    if (myTabs?.length > 0 && tabs?.length > myTabs?.length)
      setActiveTab(tabs?.[tabs?.length - 1]?.id);
    else setActiveTab(tabs?.[0]?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs]);

  const handleTabClick = (id: string) => {
    setActiveTab(id);
  };

  return (
    <div className={clsx("tabs", className)}>
      <ul className="nav nav-tabs">
        {myTabs.map((tab) => (
          <li
            key={tab.id}
            className={clsx("nav-item", activeTab === tab.id && "active")}
          >
            <Link
              className={clsx("nav-link", activeTab === tab.id && "active")}
              to={`#${tab.id}`}
              onClick={(e) => {
                e.preventDefault();
                handleTabClick(tab.id);
              }}
            >
              {tab.icon && <i className={tab.icon} />} {tab.title || "New"}
            </Link>
          </li>
        ))}
      </ul>
      <div className="tab-content">
        {myTabs.map((tab) => (
          <div
            key={tab.id}
            id={tab.id}
            className={`tab-pane ${activeTab === tab.id ? "active" : ""}`}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
};
