import clsx from "clsx";
import React from "react";

import { useController, useFormContext } from "react-hook-form";

import { createFormComponent } from "[root]/src/components/hocs";
import { FormComponentBaseProps } from "[root]/src/lib";

export type TextFieldProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "size"
> &
  FormComponentBaseProps & {
    placeholder?: string;
    size?: "sm" | "lg";
  };

const InputElement = (props: React.InputHTMLAttributes<HTMLInputElement>) => {
  return <input {...props} />;
};

const InputFormComponent = createFormComponent(InputElement);

export const TextField = ({
  name,
  className,
  containerClassName,
  size,
  ...props
}: TextFieldProps) => {
  const { control } = useFormContext();

  const { field, fieldState } = useController({
    name,
    control,
    defaultValue: "",
  });

  return (
    <div className={clsx("input-group mb-3 d-block", containerClassName)}>
      <InputFormComponent
        name={name}
        className={clsx(
          "form-control",
          size === "lg" && "form-control-lg",
          size === "sm" && "form-control-sm",
          className
        )}
        error={fieldState.error}
        onChange={field.onChange}
        onBlur={props.onBlur || field.onBlur}
        value={field.value.toString()}
        {...props}
      />
    </div>
  );
};
