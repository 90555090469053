import { useEffect } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { HoleData, Tee, useTeeSchema } from "@stesvis/metagolf-apis";
import { Variant } from "@stesvis/react-core";
import Enumerable from "linq";
import { Col, Row } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { MdDelete } from "react-icons/md";
import { v4 } from "uuid";

import { Button } from "[root]/src/components/atoms";
import { Form, TextField } from "[root]/src/components/forms";
import { useCoursesApi } from "[root]/src/components/pages/golf-courses";
import { useModalContext } from "[root]/src/contexts";
import { IconSize } from "[root]/src/lib";
import { useServices } from "[root]/src/services";

type TeeFormProps = {
  courseId: number;
  tee: Tee;
  holes?: HoleData[];
  onLoading?: (loading: boolean) => void;
};

export const TeeForm = ({
  courseId,
  tee,
  holes,
  onLoading,
  ...props
}: TeeFormProps) => {
  const services = useServices();
  const { showModal, hideModal } = useModalContext();
  const { coursesSaveTeeApiMutation, coursesDeleteTeeApiMutation } =
    useCoursesApi();

  const formatDetails = (holes?: HoleData[]) =>
    Enumerable.from(holes || [])
      .select((x) => ({
        holeId: x.id,
        holeNumber: x?.number,
        yardage: tee.holesDetails.find((y) => y.holeId === x.id)?.yardage,
      }))
      .toArray();

  const schema = useTeeSchema();
  const methods = useForm({
    defaultValues: {
      id: tee?.id ?? 0,
      name: tee?.name,
      courseId: courseId,
      holesDetails: formatDetails(holes),
    },
    mode: "all",
    resolver: yupResolver(schema),
  });
  const { control, reset } = methods;
  const { fields: holesDetails } = useFieldArray({
    control,
    name: "holesDetails",
  });

  useEffect(() => {
    reset({
      id: tee?.id ?? 0,
      name: tee?.name,
      courseId: courseId,
      holesDetails: formatDetails(holes),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId, tee, holes, reset]);

  // Tell the parent component that we are loading
  useEffect(() => {
    onLoading?.(
      coursesSaveTeeApiMutation.isPending ||
        coursesDeleteTeeApiMutation.isPending
    );
  }, [
    coursesSaveTeeApiMutation.isPending,
    coursesDeleteTeeApiMutation.isPending,
    onLoading,
  ]);

  // Save the hole group
  const onSubmit = (data: Tee) => {
    coursesSaveTeeApiMutation
      .mutateAsync({ id: courseId, tee: data })
      .then((data) => {
        services.utilities.toast({
          variant: Variant.success,
          message: data.message || "Success!",
        });
      });
  };

  return (
    <Form methods={methods}>
      <Row>
        <Col lg={4}>
          <TextField
            name="name"
            label="Tee Name"
            placeholder="Example: Blue"
            size="lg"
          />
        </Col>
        <Col className="text-right d-flex align-items-center justify-content-end">
          {tee?.id && (
            <MdDelete
              size={IconSize.medium}
              className="text-danger cur-pointer"
              onClick={() =>
                showModal({
                  title: "Delete Tee",
                  body: (
                    <>
                      Are you sure you want to delete this tee?
                      <br />
                      <label className="error">
                        This wil affect the scorecards.
                      </label>
                    </>
                  ),
                  buttonsProps: {
                    type: "two-buttons",
                    leftButtonProps: {
                      title: "Cancel",
                      variant: Variant.tertiary,
                      onClick: hideModal,
                    },
                    rightButtonProps: {
                      title: "Delete",
                      variant: Variant.error,
                      onClick: async () => {
                        if (tee?.id === undefined) return;

                        coursesDeleteTeeApiMutation.mutateAsync({
                          id: courseId,
                          teeId: tee.id,
                        });
                        hideModal();
                      },
                    },
                  },
                })
              }
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <h5 className="font-weight-semibold">
            Distance from middle of the green
          </h5>
        </Col>
      </Row>
      <Row>
        {holesDetails?.map((holeDetails, index) => (
          <Col key={v4()} lg={4}>
            <TextField
              label={`Hole ${holeDetails.holeNumber}`}
              name={`holesDetails.${index}.yardage`}
              placeholder={`Hole ${holeDetails.holeNumber} yardage`}
            />
          </Col>
        ))}
      </Row>
      <Row>
        <Col className="text-right">
          <Button onClick={methods.handleSubmit(onSubmit)}>Save</Button>
        </Col>
      </Row>
    </Form>
  );
};
