export const MGRoutes = {
  login: "/login",
  register: "/register",

  dashboard: "/",

  // golf courses
  golfCourses: "/golf-courses",
  golfCourses_create: "/golf-courses/create",
  golfCourses_details: "/golf-courses/:id",

  // errors
  notFound: "/not-found",
};
