import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { AxiosError, HttpStatusCode } from "axios";

import { ErrorResponse } from "@stesvis/metagolf-apis";
import { useAuthContext } from "../contexts";
import { useServices } from "../services";

export const useApiMutation = <TData, TError = AxiosError, TVariables = void>(
  options: UseMutationOptions<TData, TError, TVariables>
) => {
  const { signOut, saveSession, session } = useAuthContext();
  const services = useServices();

  // Wrap the mutation function to handle retry logic manually
  const wrappedMutationFn = async (variables: TVariables) => {
    try {
      return await options.mutationFn!(variables); // Call original mutation function
    } catch (error) {
      if (shouldRetry(error as AxiosError | ErrorResponse)) {
        // Attempt token refresh if retry condition is met
        if (session?.token && session?.refreshToken) {
          try {
            const response = await services.api.auth.refreshToken({
              access_token: session.token,
              refresh_token: session.refreshToken,
            });
            if (response.access_token && response.refresh_token) {
              saveSession({
                token: response.access_token,
                refreshToken: response.refresh_token,
              });
              // Retry the mutation after refreshing the token
              return await options.mutationFn!(variables);
            }
          } catch (refreshError) {
            exit();
          }
        } else {
          exit();
        }
      }
      throw error; // Re-throw the error if no retry or refresh fails
    }
  };

  // Synchronous retry function
  const modifiedOptions = {
    ...options,
    mutationFn: wrappedMutationFn,
    retry: false, // Disable built-in retry as we're handling it manually
  };

  function exit() {
    console.warn("Retry count exceeded. Signing out...");
    signOut();
    return false;
  }

  function shouldRetry(error: AxiosError | ErrorResponse) {
    const axiosError = error as AxiosError;
    const responseError = error as ErrorResponse;

    return (
      axiosError.response?.status === HttpStatusCode.Unauthorized ||
      axiosError.response?.status === 419 ||
      responseError?.statusCode === HttpStatusCode.Unauthorized ||
      responseError?.statusCode === 419
    );
  }

  return useMutation<TData, TError, TVariables>(modifiedOptions);
};
