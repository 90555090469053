import { Navigate, RouteProps } from "react-router-dom";

import { useAuthContext } from "[root]/src/contexts";
import { MGRoutes } from "[root]/src/lib";
import { Overlay } from "../components";

type RequireAuthProps = RouteProps & {
  children: JSX.Element;
  redirectTo?: string;
};

export const RequireAuth = ({
  children,
  redirectTo = MGRoutes.login,
}: RequireAuthProps): JSX.Element | null => {
  const { session, loading } = useAuthContext();
  // Show nothing or a loading indicator while the session is being loaded
  if (loading) {
    return <Overlay />;
  }

  const isAuthenticated = !!session?.token;

  return isAuthenticated ? (
    children
  ) : (
    <Navigate to={redirectTo} replace={false} />
  );
};
