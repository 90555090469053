import clsx from "clsx";

import { ComponentBaseProps } from "[root]/src/lib";
import { CardBody } from "./CardBody";
import { CardFooter } from "./CardFooter";
import { CardHeader } from "./CardHeader";

type CardProps = ComponentBaseProps & {};

export const Card = ({ children, className, ...props }: CardProps) => {
  return <section className={clsx("card", className)}>{children}</section>;
};

Card.Header = CardHeader;
Card.Body = CardBody;
Card.Footer = CardFooter;
