import { Link } from "react-router-dom";

import avatar from "[root]/src/assets/images/default-avatar.jpg";
import { useAuthApi } from "[root]/src/components/pages/auth";
import { useAuthContext } from "[root]/src/contexts";

type UserBoxProps = {};

export const UserBox = (props: UserBoxProps) => {
  const { currentUser, signOut } = useAuthContext();
  const { logOutApiMutation } = useAuthApi();

  const handleLogOut = async () => {
    await logOutApiMutation.mutateAsync(false);
    signOut();
  };

  return (
    <div id="userbox" className="userbox">
      <Link to="#" data-bs-toggle="dropdown">
        <figure className="profile-picture rounded-circle">
          <img
            src={currentUser?.profilePictureUrl || avatar}
            alt={currentUser?.fullName}
            className="rounded-circle"
            data-lock-picture="img/!logged-user.jpg"
          />
        </figure>

        {/* If no avatar is available, use the following code snippet: */}
        {/* <span className="profile-picture profile-picture-as-text">JD</span> */}
        <div
          className="profile-info"
          data-lock-name={currentUser?.fullName}
          data-lock-email={currentUser?.email}
        >
          <strong className="font-weight-semibold name">
            {currentUser?.fullName}
          </strong>
          <span className="role">{currentUser?.roles[0]}</span>
        </div>

        <i className="fas fa-chevron-down text-color-light" />
      </Link>
      <div className="dropdown-menu">
        <ul className="list-unstyled mt-3">
          <li>
            <Link role="menuitem" tabIndex={-1} to="pages-user-profile.html">
              <i className="bx bx-user" /> My Profile
            </Link>
          </li>
          <li>
            <Link role="menuitem" tabIndex={-1} to="#" data-lock-screen="true">
              <i className="bx bx-lock-open-alt" /> Lock Screen
            </Link>
          </li>
          <li>
            <Link
              role="menuitem"
              tabIndex={-1}
              to="#"
              onClick={handleLogOut}
              className="text-danger"
            >
              <i className="bx bx-log-out" /> Logout
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
