import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { AuthContextProvider, ModalContextProvider } from "[root]/src/contexts";
import { NavigationContainer } from "[root]/src/navigation";
// import "./index.css";
import "./styles/custom.css";
// import "./styles/toggle.css";

const queryClient = new QueryClient();

function App() {
  return (
    <Router>
      {/* For @tanstack/react-query */}
      <QueryClientProvider client={queryClient}>
        <ModalContextProvider>
          <AuthContextProvider>
            <NavigationContainer />

            {/* Handle toast messages UI */}
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
            />
          </AuthContextProvider>
        </ModalContextProvider>
      </QueryClientProvider>
    </Router>
  );
}

export default App;
