import { useEffect } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { HoleGroup, useHolesSchema } from "@stesvis/metagolf-apis";
import { Variant } from "@stesvis/react-core";
import { Col, Row } from "react-bootstrap";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { MdDelete } from "react-icons/md";

import { Button } from "[root]/src/components/atoms";
import { Form, TextField } from "[root]/src/components/forms";
import { useCoursesApi } from "[root]/src/components/pages/golf-courses";
import { useModalContext } from "[root]/src/contexts";
import { IconSize } from "[root]/src/lib";
import { useServices } from "[root]/src/services";

type HolesGroupFormProps = {
  courseId: number;
  holeGroup?: HoleGroup;
  firstHoleNumber: number;
  onLoading?: (loading: boolean) => void;
  onGoupNameChange?: (groupName: string) => void;
};

export const HolesGroupForm = ({
  courseId,
  holeGroup,
  firstHoleNumber,
  onLoading,
  onGoupNameChange,
  ...props
}: HolesGroupFormProps) => {
  const { coursesSaveHolesApiMutation, coursesDeleteHolesApiMutation } =
    useCoursesApi();
  const services = useServices();
  const { showModal, hideModal } = useModalContext();

  const schema = useHolesSchema();
  const methods = useForm({
    defaultValues: {
      groupId: holeGroup?.groupId,
      groupName: holeGroup?.groupName,
      holes:
        holeGroup?.holes ||
        Array(9)
          .fill(undefined)
          .map((_, index) => ({
            id: undefined,
            number: firstHoleNumber + index,
            middleOfGreen: undefined,
          })),
    },
    mode: "all",
    resolver: yupResolver(schema),
  });
  const { control } = methods;
  const groupNameWatch = useWatch({ control, name: "groupName" });

  const { fields } = useFieldArray({
    control,
    name: "holes",
  });

  useEffect(() => {
    onGoupNameChange?.(groupNameWatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupNameWatch]);

  // Tell the parent component that we are loading
  useEffect(() => {
    onLoading?.(
      coursesSaveHolesApiMutation.isPending ||
        coursesDeleteHolesApiMutation.isPending
    );
  }, [
    coursesSaveHolesApiMutation.isPending,
    coursesDeleteHolesApiMutation.isPending,
    onLoading,
  ]);

  // Save the hole group
  const onSubmit = (data: HoleGroup) => {
    coursesSaveHolesApiMutation
      .mutateAsync({ id: courseId, holeGroup: data })
      .then((data) => {
        services.utilities.toast({
          variant: Variant.success,
          message: data.message || "Success!",
        });
      });
  };

  return (
    <Form methods={methods}>
      <Row>
        <Col lg={4}>
          <TextField
            name="groupName"
            label="Group Name"
            placeholder="Example: Front 9"
            size="lg"
          />
        </Col>
        <Col className="text-right d-flex align-items-center justify-content-end">
          {holeGroup?.groupId && (
            <MdDelete
              size={IconSize.medium}
              className="text-danger cur-pointer"
              onClick={() =>
                showModal({
                  title: "Delete Holes",
                  body: "Are you sure you want to delete these holes?",
                  buttonsProps: {
                    type: "two-buttons",
                    leftButtonProps: {
                      title: "Cancel",
                      variant: Variant.tertiary,
                      onClick: hideModal,
                    },
                    rightButtonProps: {
                      title: "Delete",
                      variant: Variant.error,
                      onClick: async () => {
                        if (holeGroup?.groupId === undefined) return;

                        coursesDeleteHolesApiMutation.mutateAsync({
                          id: courseId,
                          holeGroupId: holeGroup?.groupId,
                        });
                        hideModal();
                      },
                    },
                  },
                })
              }
            />
          )}
        </Col>
      </Row>
      <Row>
        {fields?.map((hole, index) => (
          <Col key={hole.id} lg={4}>
            <TextField
              name={`holes.${index}.middleOfGreen`}
              label={`Hole ${hole.number}`}
              placeholder="Middle of green"
            />
          </Col>
        ))}
      </Row>
      <Row>
        <Col className="text-right">
          <Button onClick={methods.handleSubmit(onSubmit)}>Save</Button>
        </Col>
      </Row>
    </Form>
  );
};
