import { AiFillHome } from "react-icons/ai";
import { IoGolfSharp } from "react-icons/io5";
import { useLocation } from "react-router-dom";

import { useUserInfo } from "[root]/src/hooks";
import { MGRoutes } from "[root]/src/lib";
import { HamburgerButton } from "./HamburgerButton";
import { SideBarMenuItem } from "./SideBarMenuItem";

type SideBarProps = {};

export const SideBar = (props: SideBarProps) => {
  const { pathname } = useLocation();
  const { isAdmin } = useUserInfo();

  return (
    <aside id="sidebar-left" className="sidebar-left">
      <div className="sidebar-header">
        <div className="sidebar-title text-muted text-uppercase">Menu</div>
        <HamburgerButton
          className="hidden-mobile"
          dataToggleClassName="sidebar-left-collapsed"
        />
      </div>
      <div className="nano">
        <div className="nano-content">
          <nav id="menu" className="nav-main" role="navigation">
            <ul className="nav nav-main">
              <SideBarMenuItem
                to={MGRoutes.dashboard}
                title="Dashboard"
                active={pathname === MGRoutes.dashboard}
                IconComponent={() => <AiFillHome />}
              />
              {!!isAdmin && (
                <SideBarMenuItem
                  to={MGRoutes.golfCourses}
                  title="Golf Courses"
                  active={pathname.startsWith(MGRoutes.golfCourses)}
                  IconComponent={() => <IoGolfSharp />}
                />
              )}
              {/* <SidebarMenu
                title="Golf Courses"
                active={pathname.startsWith(MGRoutes.golfCourses)}
                IconComponent={() => <IoGolfSharp />}
              >
                <SideBarMenuItem
                  to={MGRoutes.golfCourses}
                  title="View All"
                  active={pathname === MGRoutes.golfCourses}
                />
                <SideBarMenuItem
                  to={MGRoutes.golfCourses_create}
                  title="Add New"
                  active={pathname === MGRoutes.golfCourses_create}
                />
              </SidebarMenu> */}
            </ul>
          </nav>
        </div>
      </div>
    </aside>
  );
};
