import React from "react";

import {
  FieldValues,
  FormProvider,
  UseFormReturn,
  useWatch,
} from "react-hook-form";

import { ComponentBaseProps } from "[root]/src/lib";

type FormProps<T extends FieldValues = FieldValues> = ComponentBaseProps & {
  methods: UseFormReturn<T>;
};

export const Form = <T extends Record<string, any>>({
  children,
  className = "",
  methods,
}: FormProps<T>) => {
  const watch = useWatch({ control: methods.control });

  return (
    <FormProvider {...methods}>
      <div className={className}>
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child) && child.props.name) {
            return React.cloneElement(child, {
              ...{
                ...child.props,
                control: methods.control,
                key: child.props.name,
              },
            });
          }
          return child;
        })}
      </div>
      {false && process.env.REACT_APP_NODE_ENV === "development" && (
        <div className="CodeMirror cm-s-monokai">
          <pre>{JSON.stringify(methods.formState.errors, null, 2)}</pre>
          <pre>{JSON.stringify(watch, null, 2)}</pre>
        </div>
      )}
    </FormProvider>
  );
};
