import {
  AppleSignInRequest,
  AuthTokenResponse,
  Endpoints,
  ErrorResponse,
  GoogleSignInRequest,
  LoginRequest,
  ResponseBase,
  SignUpRequest,
} from "@stesvis/metagolf-apis";
import { Variant } from "@stesvis/react-core";
import { useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";

import { useAccountApi } from "[root]/src/components/pages";
import { useAuthContext } from "[root]/src/contexts";
import { useApiMutation } from "[root]/src/hooks";
import { MGRoutes } from "[root]/src/lib";
import { useServices } from "[root]/src/services";
import { useLocationsApi } from "./useLocationsApi";

export const useAuthApi = () => {
  const { saveSession, saveCurrentUser } = useAuthContext();

  const services = useServices();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { userInfoApi } = useAccountApi();
  const { locationsApi } = useLocationsApi();

  const location = useLocation();
  const segments = location.pathname.split("/").filter(Boolean);

  // Token API mutation for login
  const tokenApiMutation = useApiMutation<
    AuthTokenResponse,
    Error,
    LoginRequest
  >({
    mutationKey: [Endpoints.Auth.token],
    mutationFn: services.api.auth.token,
    onSuccess: (data) => {
      saveSession({
        token: data.access_token,
        refreshToken: data.refresh_token,
      });
      services.utilities.invalidateQueries(); // clear all cached queries
      queryClient.clear();

      userInfoApi
        .refetch({ throwOnError: true })
        .then((result) => saveCurrentUser(result.data?.data))
        .catch((err) =>
          services.utilities.toast({
            message: err.message,
            variant: Variant.error,
          })
        );
      navigate(MGRoutes.dashboard);
    },
    onError: (error) => {
      services.utilities.toast({
        variant: Variant.error,
        message: error.message,
      });
    },
  });

  // Registration API mutation
  const registerApiMutation = useApiMutation<
    AuthTokenResponse,
    Error,
    SignUpRequest
  >({
    mutationKey: [Endpoints.Auth.signUp],
    mutationFn: services.api.auth.register,
    onSuccess: (data) => {
      saveSession({
        token: data.access_token,
        refreshToken: data.refresh_token,
      });
      userInfoApi
        .refetch({ throwOnError: true })
        .then((result) => {
          saveCurrentUser(result.data?.data);
          services.utilities.toast({
            message: `Welcome, ${result.data?.data?.userName}!`,
            variant: Variant.success,
          });
        })
        .catch((err) =>
          services.utilities.toast({
            message: err.message,
            variant: Variant.error,
          })
        );
      navigate(MGRoutes.dashboard);
    },
    onError: (error: ErrorResponse) => {
      services.utilities.toast({
        message: `${error.message}\n\n${error.details}`,
        variant: Variant.error,
      });
    },
  });

  // Log out API mutation
  const logOutApiMutation = useApiMutation<ResponseBase, Error, boolean>({
    mutationKey: [Endpoints.Auth.logOut],
    mutationFn: services.api.auth.logOut,
    onError: (error) => {
      services.utilities.toast({
        variant: Variant.error,
        message: error.message,
      });
    },
  });

  // Apple Login API mutation
  const appleLoginApiMutation = useApiMutation<
    AuthTokenResponse,
    Error,
    AppleSignInRequest
  >({
    mutationKey: [Endpoints.OAuth.appleSignIn],
    mutationFn: services.api.oauth.appleLogin,
    onSuccess: (data) => {
      saveSession({
        token: data.access_token,
        refreshToken: data.refresh_token,
      });
      services.utilities.invalidateQueries(); // clear all cached queries

      userInfoApi
        .refetch({ throwOnError: true })
        .then((result) => saveCurrentUser(result.data?.data))
        .catch((err) =>
          services.utilities.toast({
            message: err.message,
            variant: Variant.error,
          })
        );
      navigate(MGRoutes.dashboard);
    },
    onError: (error) => {
      services.utilities.toast({
        variant: Variant.error,
        message: error.message,
      });
    },
  });

  // Google Login API mutation
  const googleLoginApiMutation = useApiMutation<
    AuthTokenResponse,
    Error,
    GoogleSignInRequest
  >({
    mutationKey: [Endpoints.OAuth.googleSignIn],
    mutationFn: services.api.oauth.googleLogin,
    onSuccess: (data) => {
      saveSession({
        token: data.access_token,
        refreshToken: data.refresh_token,
      });
      services.utilities.invalidateQueries(); // clear all cached queries

      userInfoApi
        .refetch({ throwOnError: true })
        .then((result) => saveCurrentUser(result.data?.data))
        .catch((err) =>
          services.utilities.toast({
            message: err.message,
            variant: Variant.error,
          })
        );
      navigate(MGRoutes.dashboard);
    },
    onError: (error) => {
      services.utilities.toast({
        variant: Variant.error,
        message: error.message,
      });
    },
  });

  return {
    tokenApiMutation,
    registerApiMutation,
    logOutApiMutation,
    userInfoApi,
    locationsApi,
    appleLoginApiMutation,
    googleLoginApiMutation,
  };
};
