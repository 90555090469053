import { ComponentBaseProps } from "[root]/src/lib";
import clsx from "clsx";

type EmptyDataBoxProps = ComponentBaseProps & {
  text?: string;
};

export const EmptyDataBox = ({
  className,
  text = "There are no records to display.",
  ...props
}: EmptyDataBoxProps) => {
  return (
    <div className={clsx("empty-result-info", className)}>
      <span>{text}</span>
    </div>
  );
};
