import { ComponentBaseProps } from "[root]/src/lib/interfaces";
import clsx from "clsx";
import { ComponentType } from "react";

// Define the props for WithNotes
export interface WithNotesProps {
  notes?: string;
  notesClassName?: string;
}

// Higher-order component (HOC) to add notes functionality
export function withNotes<TProps>(
  Component: ComponentType<TProps>
): React.ComponentType<TProps & WithNotesProps & ComponentBaseProps> {
  return function WithNotes({
    notes,
    notesClassName = "",
    ...props
  }: TProps & WithNotesProps & ComponentBaseProps) {
    return (
      <>
        {/* Render the wrapped component */}
        <Component {...(props as any)} />

        {/* Render notes if provided */}
        {!!notes && (
          <div className={clsx("form-text", notesClassName)}>{notes}</div>
        )}
      </>
    );
  };
}
