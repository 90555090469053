import { yupResolver } from "@hookform/resolvers/yup";
import { LoginForm, useSignInSchema } from "@stesvis/metagolf-apis";
import { Variant } from "@stesvis/react-core";
import { useForm } from "react-hook-form";

import { Button } from "[root]/src/components/atoms";
import { Form, TextField } from "[root]/src/components/forms";
import { useAuthApi } from "[root]/src/components/pages/auth";
import { AuthPageTemplate } from "[root]/src/components/templates";

type SignInPageProps = {};

export const SignInPage = (props: SignInPageProps) => {
  const schema = useSignInSchema();
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues:
      process.env.REACT_APP_NODE_ENV === "development"
        ? {
            userName: "johndoe",
            // email: "stesvis",
            password: "Password%1",
          }
        : undefined,
    mode: "all",
  });

  const { tokenApiMutation, userInfoApi } = useAuthApi();

  const onSubmit = async (data: LoginForm) => {
    await tokenApiMutation.mutateAsync(data);
  };

  return (
    <AuthPageTemplate title="Sign In">
      <label className="login-box-msg">Sign in to start your session</label>
      <Form methods={methods}>
        <TextField
          name="userName"
          placeholder="Username"
          label="Username"
          size="lg"
        />
        <TextField
          name="password"
          placeholder="Password"
          type="password"
          label="Password"
          size="lg"
        />
        <div className="row">
          <div className="col-8"></div>
          <div className="col-4">
            <div className="d-grid gap-2">
              <Button
                variant={Variant.primary}
                onClick={methods.handleSubmit(onSubmit)}
                loading={tokenApiMutation.isPending || userInfoApi.isFetching}
              >
                Sign In
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </AuthPageTemplate>
  );
};
