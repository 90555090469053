import clsx from "clsx";
import React from "react";
import { FieldError } from "react-hook-form";

// Types for the props
export interface WithErrorProps {
  error?: FieldError | null;
  errorClassName?: string; // Error message class for styling
}

export function withError<TProps>(
  Component: React.ComponentType<TProps>
): React.ComponentType<TProps & WithErrorProps> {
  return function WithError({
    error,
    errorClassName = "",
    ...props
  }: TProps & WithErrorProps) {
    return (
      <>
        {/* Render the wrapped component */}
        <Component {...(props as any)} />

        {/* Display error message if exists */}
        {error && (
          <label className={clsx("error", errorClassName)}>
            {error.message}
          </label>
        )}
      </>
    );
  };
}
