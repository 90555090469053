import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ApiSessionProps, User } from "@stesvis/metagolf-apis";
import { LocalStorageKeys } from "@stesvis/react-core";

import { MGRoutes } from "[root]/src/lib";
import { useServices } from "[root]/src/services";

interface AuthContextProps {
  saveSession: (session: ApiSessionProps) => void;
  signOut: () => void;
  session?: ApiSessionProps;
  currentUser?: User;
  saveCurrentUser: (user: User | undefined) => Promise<void>;
  loading: boolean;
}

export const AuthContext = React.createContext<AuthContextProps>({
  saveSession: async () => {}, // Change the return type to Promise<void>
  signOut: async () => {},
  session: undefined,
  currentUser: undefined,
  saveCurrentUser: async () => {},
  loading: true,
});

export const AuthContextProvider = (props: React.PropsWithChildren) => {
  const [currentUser, setCurrentUser] = useState<User>();
  const [accessToken, setAccessToken] = useState<string>();
  const [refreshToken, setRefreshToken] = useState<string>();
  const [loading, setLoading] = useState(true);

  const services = useServices();
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentUser(
      services.core.localStorage.getProperty<User>(LocalStorageKeys.user)
    );
    setAccessToken(
      services.core.localStorage.getProperty<string>(
        LocalStorageKeys.accessToken
      )
    );
    setRefreshToken(
      services.core.localStorage.getProperty<string>(
        LocalStorageKeys.refreshToken
      )
    );
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        saveSession: (session: ApiSessionProps) => {
          setAccessToken(session.token);
          services.core.localStorage.setProperty(
            LocalStorageKeys.accessToken,
            session.token
          );
          setRefreshToken(session.refreshToken);
          services.core.localStorage.setProperty(
            LocalStorageKeys.refreshToken,
            session.refreshToken
          );
        },
        signOut: async () => {
          setCurrentUser(undefined);
          setAccessToken(undefined);
          setRefreshToken(undefined);
          services.core.localStorage.clear();
          services.core.localStorage.clear();

          navigate(MGRoutes.login); // TODO: do we need this or is it handled by the Router?
        },
        session: { token: accessToken, refreshToken },
        currentUser,
        saveCurrentUser: async (user: User | undefined) => {
          setCurrentUser(user);
          services.core.localStorage.setProperty(LocalStorageKeys.user, user);
        },
        loading,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => React.useContext(AuthContext);
