import { useController, useFormContext } from "react-hook-form";

import { FormComponentBaseProps } from "[root]/src/lib";
import clsx from "clsx";
import { Form, FormCheckProps } from "react-bootstrap";
import { createFormComponent } from "../hocs";

type CheckboxFieldProps = FormCheckProps &
  FormComponentBaseProps & {
    placeholder?: string;
    description?: string;
  };

export const CheckboxField = ({
  name,
  description,
  className,
  labelClassName,
  containerClassName,
  ...props
}: CheckboxFieldProps) => {
  const { control } = useFormContext();

  const { field, fieldState } = useController({
    name,
    control,
    defaultValue: "",
  });

  const CheckboxElement = (props: FormCheckProps) => {
    return <Form.Check {...props} type="checkbox" label={description} />;
  };

  const CheckboxFormComponent = createFormComponent(CheckboxElement);

  return (
    <CheckboxFormComponent
      name={name}
      className={clsx(
        "checkbox-custom checkbox-secondary form-check",
        className
      )}
      labelClassName="form-check-label"
      containerClassName={containerClassName}
      error={fieldState.error}
      onChange={field.onChange}
      onBlur={props.onBlur || field.onBlur}
      type="checkbox"
      checked={Boolean(field.value)}
      value={field.value.toString()}
      {...props}
    />
  );
};
