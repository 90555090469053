import { BreadcrumbItem, BreadcrumbItemProps } from "./BreadcrumbItem";

export type TitleBarProps = {
  title?: string;
  breadcrumbsProps?: BreadcrumbItemProps[];
};

export const TitleBar = ({
  title,
  breadcrumbsProps,
  ...props
}: TitleBarProps) => {
  return (
    <header className="page-header">
      <h2>{title}</h2>

      <div className="right-wrapper text-end">
        <ol className="breadcrumbs">
          {breadcrumbsProps?.map((breadcrumb, index) => (
            <BreadcrumbItem key={`breadcrumb-${index}`} {...breadcrumb} />
          ))}
        </ol>
      </div>
    </header>
  );
};
